<template>
    <svg :width="size + 'px'" :fill="color" 
    version="1.1"
    id="Layer_3"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 250 250"
    >
        <g>
            <path class="st0" d="M232.396,162.535c5.487-24.228,8.773-60.659-8.336-93.278c-4.919-9.377-11.238-17.788-18.855-25.275
                l2.143-2.056c2.661-2.552,2.661-6.686,0-9.238C185.444,11.693,155.902,0.125,125,0.125c-30.903,0-60.444,11.568-82.348,32.563
                c-2.663,2.552-2.663,6.686,0,9.238l2.144,2.057c-7.616,7.488-13.935,15.896-18.854,25.273
                c-17.109,32.618-13.823,69.047-8.338,93.276c-10.215,9.834-16.809,25.756-7.953,51.42c16.031,46.458,52.493,36.796,67.069,30.708
                c3.56-1.488,5.308-5.506,4.036-9.149l-28.625-82.097c-1.259-3.614-5.073-5.673-8.767-4.678c-3.487,0.94-8.013,2.477-12.706,4.792
                c-4.122-21.678-5.544-51.416,8.149-77.523c4.39-8.369,10.123-15.879,17.117-22.552l2.129,1.734c6.8,3.39,15.678,3.079,22.076-1.541
                c25.972-18.745,63.763-18.745,89.736,0c3.633,2.622,8.4,3.904,12.7,3.904c3.255,0,6.372-0.989,9.301-2.443l2.144-1.716
                c7.002,6.669,12.744,14.173,17.142,22.538c13.699,26.062,12.275,55.86,8.155,77.585c-4.682-2.308-9.197-3.841-12.676-4.779
                c-3.694-0.996-7.508,1.064-8.769,4.678l-28.625,82.097c-1.27,3.644,0.476,7.661,4.036,9.149
                c14.576,6.088,51.039,15.75,67.071-30.708C249.201,188.292,242.61,172.369,232.396,162.535z"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'iconHeadphone',
    components: {
        // iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>